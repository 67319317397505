@import "../../styles/_chunk";

.email-subscribe {
  p {
    margin-bottom: 0.75rem;
  }

  button {
    border: 0;

    &:hover {
      cursor: pointer;
    }
  }

  input[type="email"] {
    padding: 0.563rem 0.75rem;
  }

  input[type="email"]::placeholder {
    color: $text-muted;
  }

  .form-control {
    border: 0;
  }

  .msg-danger {
    color: $error-core;
  }

  .msg-success {
    color: $success-core;
  }
}

.email-subscribe-box,
.email-subscribe-box-large {
  text-align: left;

  a {
    color: $primary-core;
  }

  .form-container {
    margin-top: 1rem;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .input-group {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    }

    input {
      background-color: $white;
      border: 1px solid map-get($light-theme, input-border-color);
      height: 40px;
      padding-left: 1rem;
      box-shadow: none;

      &::placeholder {
        opacity: 0.4;
      }
    }

    button {
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

.email-single-btn {
  @include button-variant($primary-core, $primary-core);

  text-transform: uppercase;
  height: 40px;
  font-size: 0.875rem;
  font-weight: 700;
  padding: 0.375rem 0.75rem;
  text-align: center;
  border-radius: $border-radius-sm;
  transition: all 0.15s ease-in-out;

  &:hover {
    color: $text-black;
  }

  @include theme(light) {
    font-weight: 700;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 0.9375rem;
  }
}

// VERTICAL EMAIL SUBSCRIBE BOX

.email-subscribe-box {
  background-color: $card-background-dark;
  border-radius: $border-radius;
  color: $text-white;
  margin-bottom: 2rem;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-only(md) {
    display: flex;
  }

  p {
    margin-bottom: 0;
  }

  .email-title-container {
    align-items: center;
    background-color: $grey-13;
    display: flex;
    font-size: 18px;
    line-height: 1.4rem;
    overflow: hidden;
    padding: 1rem;
    padding-top: 0.75rem;
    position: relative;
    text-transform: uppercase;
    width: 100%;
    min-height: 90px;

    &::before {
      border-left: 100vw solid transparent;
      border-right: 50vw solid transparent;
      border-bottom: 7vw solid $card-background-dark;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 3;
      content: '';
    }

    @include media-breakpoint-only(md) {
      width: 210px;
      flex-shrink: 0;
      flex-wrap: wrap;
      padding: 1.4rem;
      padding-top: 1rem;
      min-height: 100px;

      &::before {
        border: 0;
      }
    }

    @include media-breakpoint-up(md) {
      padding: 1.4rem;
      padding-top: 0.875rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 20px;
      min-height: 110px;
      padding-top: 0.75rem;
    }

    .icon-container {
      margin-right: 1rem;

      @include media-breakpoint-up(lg) {
        margin-right: 1.2rem;
      }

      .fanatical-email-icon {
        width: 40px;
        height: 40px;
        overflow: hidden;

        img {
          height: 100%;
          width: auto;
          max-width: 100%;
        }
      }
    }

    span.title-bold {
      font-weight: 700;
    }
  }

  .email-content-container {
    padding: 1rem;
    padding-top: 1rem;

    @include media-breakpoint-up(md) {
      padding: 1.4rem;
      padding-top: 1rem;
    }

    .intro-text {
      font-weight: 400;

      span {
        font-weight: 400;
        color: $primary-core;
      }
    }

    .small-text {
      font-size: 12px;
      line-height: 1.2rem;
      margin-bottom: 0;
    }
  }
}

// VERTICAL EMAIL SUBSCRIBE BOX - LIGHT THEME

.email-subscribe-box.box-light-theme {
  background-color: $white;
  color: $text-black;

  .email-title-container {
    color: $text-white;

    &::before {
      border-bottom: 8vw solid $white;
    }
  }
}

// VERTICAL EMAIL SUBSCRIBE BOX - OFF WHITE THEME

.email-subscribe-box.box-off-white-theme {
  background-color: $grey-2;
  color: $text-black;

  .email-title-container {
    color: $text-white;

    &::before {
      border-bottom: 8vw solid $grey-2;
    }
  }
}

// HORIZONTAL EMAIL SUBSCRIBE BOX

.email-subscribe-box-large {
  background-color: $card-background-dark;
  color: $text-white;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: $border-radius;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  p {
    margin-bottom: 0;
  }

  .email-title-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    background: $grey-13;
    padding: 1rem;
    padding-top: 0.75rem;
    font-size: 18px;
    line-height: 1.4rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    min-height: 90px;

    @include media-breakpoint-up(md) {
      width: 210px;
      flex-shrink: 0;
      flex-wrap: wrap;
      padding: 1.4rem;
      padding-top: 1rem;
      min-height: 100px;
    }

    @include media-breakpoint-up(lg) {
      width: 300px;
      font-size: 20px;
      background: linear-gradient(-66deg, $card-background-dark 0%, $card-background-dark 20%, $grey-13 20%, $grey-13 100%);
    }

    @include media-breakpoint-up(xl) {
      width: 330px;
      font-size: 22px;
      line-height: 1.6rem;
      padding: 2rem;
    }

    .icon-container {
      margin-right: 0.875rem;

      @include media-breakpoint-up(lg) {
        margin-right: 1.2rem;
      }

      .fanatical-email-icon {
        width: 40px;
        height: 40px;
        overflow: hidden;

        img {
          height: 100%;
          width: auto;
          max-width: 100%;
        }
      }
    }

    span.title-bold {
      font-weight: 700;
    }

    &::before {
      border-left: 100vw solid transparent;
      border-right: 50vw solid transparent;
      border-bottom: 7vw solid $card-background-dark;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 3;
      content: '';

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  .email-content-container {
    padding: 1rem;
    flex-grow: 1;

    @include media-breakpoint-up(md) {
      padding: 1rem 1.5rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 1rem 2rem;
      padding-left: 0;
    }

    .intro-text {
      font-weight: 400;
      font-size: 1rem;

      span {
        font-weight: 400;
        color: $primary-core;
      }
    }

    .small-text {
      font-size: 12px;
      line-height: 1.2rem;
    }
  }
}

// HORIZONTAL EMAIL SUBSCRIBE BOX - LIGHT THEME

.email-subscribe-box-large.box-light-theme {
  background-color: $white;
  color: $text-black;

  .email-title-container {
    color: $text-white;

    @include media-breakpoint-up(lg) {
      background: linear-gradient(-66deg, $white 0%, $white 20%, $grey-13 20%, $grey-13 100%);
    }

    &::before {
      border-bottom: 7vw solid $white;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }
}

// HORIZONTAL EMAIL SUBSCRIBE BOX - OFF WHITE THEME

.email-subscribe-box-large.box-off-white-theme {
  background-color: $grey-2;
  color: $text-black;

  .email-title-container {
    color: $text-white;

    @include media-breakpoint-up(lg) {
      background: linear-gradient(-66deg, $grey-2 0%, $grey-2 20%, $grey-13 20%, $grey-13 100%);
    }

    &::before {
      border-bottom: 7vw solid $grey-2;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }
}
